<template>
  <div class="body1" v-if="!initLoading">

    <template v-if="hotelDetail.PropertyId" >
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="{name: 'hotel_index'}">{{ $t('hotel_index') }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="{ name:'hotel_list', params:{ CityCode: hotel.city_code } ,query: this.$route.query}">
            <template v-if="isZhCn">
              {{ hotel.city_name_cn }}
            </template>
            <template v-else>
              {{ hotel.city_name_en }}
            </template>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="hotel">
          <template v-if="isZhCn">{{
              isEmpty(hotel.hotel_name_cn) ? hotel.hotel_name_en : (hotel.hotel_name_cn + ' (' + hotel.hotel_name_en + ')')
            }}
          </template>
          <template v-else>{{ hotel.hotel_name_en }}</template>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <template v-else>
      <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'hotel_index'}">{{ $t('hotel_index') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="hotelDetail.City">
        <router-link :to="{ name:'hotel_list', params:{ CityCode: hotelDetail.City.city_code } ,query: this.$route.query}">
          <template v-if="isZhCn">
            {{ hotelDetail.City.city_name_cn }}, {{ hotelDetail.Country.country_name_cn }}
          </template>
          <template v-else>
            {{ hotelDetail.City.city_name_en }}, {{ hotelDetail.Country.country_name }}
          </template>
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="hotel">
        <template v-if="isZhCn">{{
            isEmpty(hotel.hotel_name_cn) ? hotel.hotel_name_en : (hotel.hotel_name_cn + ' (' + hotel.hotel_name_en + ')')
          }}
        </template>
        <template v-else>{{ hotel.hotel_name_en }}</template>
      </a-breadcrumb-item>
    </a-breadcrumb>
    </template>

    <div id="hotel_search" style="margin:20px auto;">
      <hotel-search ref="hotel_search_detail" @searchGo="searchGo" :prop-keywords="propKeywords"></hotel-search>
    </div>

    <template v-if="hotelDetail.PropertyId" >
      <a-row class="hotel-detail border">
        <a-col :span="13">
          <p class="hotel-name" v-if="isZhCn">
            {{ isEmpty(hotel.hotel_name_cn) ? hotel.hotel_name_en : (hotel.hotel_name_cn + ' (' + hotel.hotel_name_en + ')') }}
          </p>
          <p class="hotel-name" v-else>
            {{ hotel.hotel_name_en }}
          </p>
          <div class="center-between p">
            <span v-if="parseInt(hotel.hotel_star)">
              <StarFilled class="star-color" v-for="i in parseInt(hotel.hotel_star)" v-bind:key="i"/>
            </span>
            <a-popover trigger="hover" placement="bottom">
              <template #title>
                <strong class="primary-color">{{ $t('guest_reviews', {n:hotelDetail.Rating.g_count}) }}</strong>
              </template>
              <template #content>
                <a-row :gutter="16" style="width: 450px;">
                  <template v-for="(val,key,index) in hotelDetail.Rating" v-bind:key="index">
                    <a-col :span="12" v-if="key.indexOf('g_') === 0 && val <= 5 && key !== 'g_count'">
                        {{ $t(key) }} <br />
                        <a-progress :percent="val/5 * 100" status="active" :format="percent => `${val}/5`" />
                    </a-col>
                  </template>
                </a-row>
              </template>
              <span class="guest_rating">
                  {{ $t('guest_rating') }}
                  <strong>{{ hotelDetail.Rating.g_overall }}</strong>
              </span>
            </a-popover>
          </div>
          <p class="hotel-address" v-if="isZhCn">
            {{ isEmpty(hotel.hotel_address_cn) ? hotel.hotel_address_en : hotel.hotel_address_cn }}
            <a-button type="link" size="small" @click="initMap">
              <template #icon>
                <EnvironmentFilled/>
              </template>
              {{$t('map_view')}}
            </a-button>
          </p>
          <p class="hotel-address" v-else>
            {{ hotel.hotel_address_en }}
            <a-button type="link" size="small" @click="initMap">
              <template #icon>
                <EnvironmentFilled/>
              </template>
              {{$t('map_view')}}
            </a-button>
          </p>
          <div class="center-between">
            <a-space>
              <a-button @click="clickFavorite">
                <template #icon>
                  <HeartFilled v-if="hotel?.favorite_id" :style="{ color: '#f17224' }"/>
                  <HeartOutlined v-else :style="{ color: '#f17224' }"/>
                </template>
                {{ $t('favorite') }}
              </a-button>
              <a-popover trigger="hover" placement="rightBottom">
                <template #content>
                  <div v-html="hotelDetail.Descriptions.attractions"></div>
                </template>
                <a-button>
                  <template #icon>
                    <CarOutlined :style="{ color: '#1890ff' }" />
                  </template>
                  {{ $t('getting_around') }}
                </a-button>
              </a-popover>
            </a-space>
            <span v-if="hotel.minprice > 0" class="warning-color" style="font-weight: bolder;margin-right: 10px;">{{ hotel.curcode }} {{ hotel.minprice }}</span>
          </div>
          <a-divider/>
          <p class="hotel-profile" v-if="isZhCn" v-html="getProfile(hotel.hotel_desc_cn)">
          </p>
          <p class="hotel-profile" v-else v-html="getProfile(hotel.hotel_desc_en)">
          </p>
        </a-col>
        <a-col :span="11">
          <a-row :gutter="[10,0]" style="height: 100%;margin-left: 15px;cursor: pointer;" @click="imageWin=true;">
            <a-col :span="14" v-if="image1" class="hotel-image"
                   :style="{ backgroundImage: 'url(\'' + getEImageUrl(image1, true) + '\')'}">
              <span class="image-total">{{ $t('image_unit', {n: imageList.length}) }}</span>
            </a-col>
            <a-col :span="10">
              <a-row style="height: 100%">
                <a-col :span="24" v-if="image2" class="hotel-image"
                       :style="{ backgroundImage: 'url(\'' + getEImageUrl(image2, true) + '\')'}">
                </a-col>
                <a-col :span="24" v-if="image3" class="hotel-image"
                       :style="{ marginTop:'5px', backgroundImage: 'url(\'' + getEImageUrl(image3, true) +  '\')'}">
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </template>
    <template v-else>
      <a-row class="hotel-detail border">
        <a-col :span="13">
          <p class="hotel-name" v-if="isZhCn">
            {{ isEmpty(hotel.hotel_name_cn) ? hotel.hotel_name_en : (hotel.hotel_name_cn + ' (' + hotel.hotel_name_en + ')') }}
          </p>
          <p class="hotel-name" v-else>
            {{ hotel.hotel_name_en }}
          </p>
          <p v-if="parseInt(hotel.hotel_star)">
            <StarFilled class="star-color" v-for="i in parseInt(hotel.hotel_star)" v-bind:key="i"/>
          </p>
          <p class="hotel-address" v-if="isZhCn">
            {{ isEmpty(hotel.hotel_address_cn) ? hotel.hotel_address_en : hotel.hotel_address_cn }}
            <a-button type="link" size="small" @click="initMap">
              <template #icon>
                <EnvironmentFilled/>
              </template>
              {{$t('map_view')}}
            </a-button>
          </p>
          <p class="hotel-address" v-else>
            {{ hotel.hotel_address_en }}
            <a-button type="link" size="small" @click="initMap">
              <template #icon>
                <EnvironmentFilled/>
              </template>
              {{$t('map_view')}}
            </a-button>
          </p>
          <p>
            <a-button @click="clickFavorite">
              <template #icon>
                <HeartFilled v-if="hotel?.favorite_id" :style="{ color: '#f17224' }"/>
                <HeartOutlined v-else :style="{ color: '#f17224' }"/>
              </template>
              {{ $t('favorite') }}
            </a-button>
          </p>
          <a-divider/>
          <p class="hotel-profile" v-if="isZhCn" v-html="getProfile(hotel.hotel_desc_cn)">
          </p>
          <p class="hotel-profile" v-else v-html="getProfile(hotel.hotel_desc_en)">
          </p>
        </a-col>
        <a-col :span="11">
          <a-row :gutter="[10,0]" style="height: 100%;margin-left: 15px;cursor: pointer;" @click="imageWin=true;">
            <a-col :span="14" v-if="image1" class="hotel-image"
                   :style="{ backgroundImage: 'url(\'' + getImageUrl(image1) + '\')'}">
              <span class="image-total">{{ $t('image_unit', {n: imageList.length}) }}</span>
            </a-col>
            <a-col :span="10">
              <a-row style="height: 100%">
                <a-col :span="24" v-if="image2" class="hotel-image"
                       :style="{ backgroundImage: 'url(\'' + getImageUrl(image2) + '\')'}">
                </a-col>
                <a-col :span="24" v-if="image3" class="hotel-image"
                       :style="{ marginTop:'5px', backgroundImage: 'url(\'' + getImageUrl(image3) +  '\')'}">
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </template>

    <a-row id="hotel_room" class="hotel-room border">
      <div class="center-between" style="width:100%">
        <span>
          <a :class="clickButtonName === 'hotel_room'?'button-hover':'button'"
             @click="scrollToTarget('hotel_room')">{{ $t('room_price') }}</a>
          <a :class="clickButtonName === 'hotel_desc'?'button-hover':'button'"
             @click="scrollToTarget('hotel_desc')">{{ $t('hotel_overview') }}</a>
          <a :class="clickButtonName === 'hotel_policy'?'button-hover':'button'"
             @click="scrollToTarget('hotel_policy')">{{ $t('hotel_policy') }}</a>
          <a :class="clickButtonName === 'hotel_feature'?'button-hover':'button'"
             @click="scrollToTarget('hotel_feature')">{{ $t('facility_service') }}</a>
        </span>
        <a-space v-if="!rateLoading && this.roomRates.length > 0">
          <a-select :bordered="false" :dropdownMatchSelectWidth="false" :options="roomOptions" v-model:value="roomSelected" >
          </a-select>
          <a-select :bordered="false" :dropdownMatchSelectWidth="false" :options="mealOptions" v-model:value="mealSelected" >
          </a-select>
          <a-checkbox v-model:checked="refundableChecked">{{ $t('refundable') }}</a-checkbox>
          <a-checkbox v-model:checked="hotelPackage" @change="searchRooms(searchData)" v-if="hasERate">{{ $t('check_out_airfare_combination_specials') }}</a-checkbox>
        </a-space>
      </div>
      <a-divider/>
      <a-spin :tip="$t('searching_for_price')" :spinning="rateLoading" size="large" :style="{width: '100%',padding: '50px 0'}">
        <table v-if="roomRates.length > 0" class="table-rooms">
          <thead>
            <tr>
              <th style="text-align: left;">{{ $t('room_type') }}</th>
              <th style="text-align: left;width:25%">{{ $t('meal_type') }}/{{ $t('service') }}</th>
              <th style="text-align: left;width:25%">{{ $t('cancel_policy')}}</th>
              <th style="text-align: center;width:20%">{{ $t('price_room_night') }}</th>
              <th style="text-align: center;width:10%">{{ $t('reserve') }}</th>
            </tr>
          </thead>
          <tbody>
          <template v-for="(room,num) in getRoomRates" v-bind:key="num">
            <tr>
              <th colspan="5" class="room-name">
                {{ isZhCn ? getNameCnEn(room.room_name_cn, room.room_name_en) : room.room_name_en }}
                <span class="lowest_price" @click="lowestShows[room.room_id] = !lowestShows[room.room_id]">{{ $t('lowest_price') }}: <span class="warning-color">{{ searchData.CurrenyCode }} {{ $filters.formatAmount(room.lower_price,0) }}</span> <UpCircleOutlined v-if="lowestShows[room.room_id]" /><DownCircleOutlined v-else /></span>
              </th>
            </tr>
            <template v-if="lowestShows[room.room_id]">
              <template v-for="(rate,index) in room.RatePlans" v-bind:key="index">
                <tr v-if="index > 3?rateTrShows[num]:true">
                  <template v-if="room.room_id > 0">
                    <td style="vertical-align: top;color:rgba(0,0,0,0.6)" v-if="index === 0" :rowspan="rateTrShows[num]?room.RatePlans.length:4">
                      <a-space direction="vertical">
                        <template v-if="room.Details">
                          <a-image v-if="room.Details.links_jsons" :src="getLinkJsonImageUrl(room.Details.links_jsons[0], false)" :preview="false" @click="roomDetailWin=true;selectRoom=room;" class="img_radius" />
                        </template>
                        <template v-else>
                          <a-image v-if="roomImages[room.room_id]" :src="roomImages[room.room_id]" :preview="false" class="img_radius" />
                        </template>

                        <a-space align="start" v-if="room.max_pax > 0"><TeamOutlined />{{ $t('maximum_occupancy', {n: room.max_pax}) }}</a-space>
                        <a-space align="start" v-if="room.BedTypes && room.BedTypes.length > 0"><svg-bed class="room" /> {{ getBedTypeStr(room.BedTypes, this.$t) }}</a-space>
                        <a-space align="start" v-if="room.room_area"><svg-sqm class="room" />
                          {{ $t('room_size') }}: {{room.room_area}} m²</a-space>
                        <a-space align="start" v-if="room.smoking === 1"><svg-smoking class="room" />
                          {{ $t('smoking_types.' + room.smoking) }}</a-space>
                        <a-space align="start" v-if="room.smoking === 0"><svg-non-smoking class="room" />
                          {{ $t('smoking_types.' + room.smoking) }}</a-space>
                        <a-space v-if="room.Details" align="start" class="primary-color pointer" @click="roomDetailWin=true;selectRoom=room;"><PlusCircleOutlined /> {{ $t('more_detail') }}</a-space>
                      </a-space>
                    </td>
                  </template>
                  <template v-else>
                    <td class="border-bottom">
                      <span style="color: #000000">{{ rate.RatePlanName }}</span>
                    </td>
                  </template>
                  <td style="text-align:left;" class="border-bottom f13">
                    <template v-if="!rate.EpsRateInfo">
                      <div class="primary-color"><CheckCircleFilled /> {{ $t('mealtype_unit.' + rate.MealType, {n: rate.Occupancy}) }}</div>
                    </template>
                    <template v-else>
                      <div class="primary-color" v-for="(amenity,amenityIndex) in rate.EpsRateInfo.amenities" v-bind:key="amenityIndex">
                        <CheckCircleFilled /> {{ amenity.name }}
                      </div>
                      <div class="primary-color" v-for="(promotion,promotionIndex) in rate.EpsRateInfo?.promotions?.value_adds" v-bind:key="promotionIndex">
                        <CheckCircleFilled /> {{ promotion.description }}
                      </div>
                    </template>
                    <div class="primary-color"><CheckCircleFilled /> {{ $t('immediately_confirm') }}</div>
                    <div v-if="rate.EpsRateInfo && rate.EpsRateInfo.sale_scenario.package" class="accent-color">
                      <CheckCircleFilled /> {{ $t('airfare_combination_specials') }}
                    </div>
                  </td>
                  <td class="border-bottom f13">
                    <template v-if="!rate.EpsRateInfo">
                      <template v-if="!isRefundable(rate.CancelAdvanceHour, dayjs(this.searchData.CheckIn), dayjs())">
                        <a-popover placement="topLeft" trigger="hover">
                          <template #content>
                            {{ $t('non_refundable_remark') }}
                          </template>
                          <div class="error-color"><InfoCircleOutlined /> {{ $t('non_refundable') }}</div>
                        </a-popover>
                      </template>
                      <template v-else>
                        <a-popover placement="topLeft" trigger="hover">
                          <template #content>
                            {{ $t('cancel_policy_info', { str: getCancelDatetime(dayjs(this.searchData.CheckIn), rate.CancelAdvanceHour), amount: rate.Currency + ' ' + $filters.formatAmount(rate.TotalPrice, 0)}) }}
                          </template>
                          <div class="primary-color"><InfoCircleOutlined /> {{ $t('refundable') }}</div>
                        </a-popover>
                        <div>
                          {{ $t('free_cancel_before', { str: getCancelDatetime(dayjs(this.searchData.CheckIn), rate.CancelAdvanceHour, 'YYYY-MM-DD HH:mm') }) }}
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="!rate.EpsRateInfo.refundable">
                        <a-popover placement="topLeft" trigger="hover">
                          <template #content>
                            {{ $t('non_refundable_remark') }}
                          </template>
                          <div class="error-color"><InfoCircleOutlined /> {{ $t('non_refundable') }}</div>
                        </a-popover>
                      </template>
                      <template v-else-if="isEpsPartiallyRefundable(rate.EpsRateInfo)">
                        <a-popover placement="topLeft" trigger="hover">
                          <template #content>
                            <div>{{ $t('cancel_policy_info_3', { f: getECancelText(rate.EpsRateInfo.cancel_penalties[0], $t, this.searchData.PaxRoom.Count) }) }}</div>
                          </template>
                          <div class="primary-color"><InfoCircleOutlined /> {{ $t('partially_refundable') }}</div>
                        </a-popover>
                      </template>
                      <template v-else>
                        <a-popover placement="topLeft" trigger="hover">
                          <template #content>
                            <div>
                              {{ $t('free_cancel_before', { str: dayjs(rate.EpsRateInfo.cancel_penalties[0].start).format('YYYY-MM-DD HH:mm') }) }};
                            </div>
                            <div v-for="(item,index) in rate.EpsRateInfo.cancel_penalties" v-bind:key="index">
                              {{ $t('cancel_policy_info_1', { s: dayjs(item.start).format('YYYY-MM-DD HH:mm'), e: dayjs(item.end).format('YYYY-MM-DD HH:mm'), f: getECancelText(item, $t, this.searchData.PaxRoom.Count) }) }}
                            </div>
                            <div>{{ $t('cancel_policy_info_2', { e: dayjs(rate.EpsRateInfo.cancel_penalties[rate.EpsRateInfo.cancel_penalties.length - 1].end).format('YYYY-MM-DD HH:mm') }) }}</div>
                            <div>({{ $t('beijing_time_remark') }})</div>
                          </template>
                          <div class="primary-color"><InfoCircleOutlined /> {{ $t('refundable') }}</div>
                        </a-popover>
                        <div>
                          {{ $t('free_cancel_before', { str: dayjs(rate.EpsRateInfo.cancel_penalties[0].start).format('YYYY-MM-DD HH:mm') }) }}
                        </div>
                      </template>
                    </template>
                  </td>
                  <td style="text-align:center;" class="border-bottom">
                    <template v-if="!isEmpty(rate.RefCurrency) && rate.Currency !== rate.RefCurrency">
                      <div class="warning-color" style="font-weight: bold;">{{ rate.RefCurrency }} {{ $filters.formatAmount(getUnitPrice(rate.RefTotalPrice), 0) }}</div>
                      <div class="ref-font">{{ $t('total_price')}} {{ rate.RefCurrency }} {{ $filters.formatAmount(rate.RefTotalPrice, 0) }}</div>
                      <div class="ref-font">{{ $t('settlement_total_price')}} {{ rate.Currency }} {{ $filters.formatAmount(rate.TotalPrice, 0) }}</div>
                    </template>
                    <template v-else>
                      <div class="warning-color" style="font-weight: bold;">{{ rate.Currency }} {{ $filters.formatAmount(getUnitPrice(rate.TotalPrice), 0) }}</div>
                      <div class="ref-font">{{ $t('total_price')}} {{ rate.Currency }} {{ $filters.formatAmount(rate.TotalPrice, 0) }}</div>
                    </template>
                    <div class="ref-font">
                      {{ $t('after_tax_fees') }}
                    </div>
                  </td>
                  <td style="text-align:center;" class="border-bottom">
                    <a-button type="primary" @click.stop="goReserve(room, rate)">{{ $t('reserve_now') }}</a-button>
                  </td>
                </tr>
              </template>
              <tr>
                <th colspan="5" style="text-align: center;padding: 10px">
                  <template v-if="room.RatePlans.length > 4">
                    <a @click="rateTrShows[num] = !rateTrShows[num]">
                      {{ rateTrShows[num] ? $t('hide') : ($t('more') + ' (' +(room.RatePlans.length - 4) + ')' ) }}
                      <template v-if="rateTrShows[num]">
                        <UpOutlined/>
                      </template>
                      <template v-else>
                        <DownOutlined/>
                      </template>
                    </a>
                  </template>
                </th>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
        <a-empty v-if="roomRates.length === 0 && !rateLoading" style="width:100%;padding: 50px 0" :description="$t('no_avail')" />
      </a-spin>
    </a-row>

    <template v-if="hotelDetail.PropertyId" >
      <a-row id="hotel_desc" class="hotel-desc border">
        <div><span class="hotel-desc-title">{{ $t('hotel_overview') }}</span></div>
        <a-divider/>
        <div class="hotel-desc-content" style="margin: 15px 0;">
          <a-space>
            <span class="c-tag" v-for="item in hotelDetail.Themes" v-bind:key="item">{{item}}</span>
          </a-space>
        </div>
        <div v-if="hotelDetail.Descriptions.location" class="hotel-desc-content">
          <div><strong>{{ $t('hotel_location') }}</strong></div>
          <div class="ti28" v-html="hotelDetail.Descriptions.location">
          </div>
        </div>
        <div v-if="hotelDetail.Descriptions.rooms" class="hotel-desc-content">
          <div><strong>{{ $t('guest_room') }}</strong></div>
          <div class="ti28" v-html="hotelDetail.Descriptions.rooms">
          </div>
        </div>
        <div v-if="hotelDetail.Descriptions.dining" class="hotel-desc-content">
          <div><strong>{{ $t('dining') }}</strong></div>
          <div class="ti28" v-html="hotelDetail.Descriptions.dining">
          </div>
        </div>
        <div v-if="hotelDetail.Descriptions.amenities" class="hotel-desc-content">
          <div><strong>{{ $t('rec_spa_amenity') }}</strong></div>
          <div class="ti28" v-html="hotelDetail.Descriptions.amenities">
          </div>
        </div>
        <div v-if="hotelDetail.Descriptions.business_amenities" class="hotel-desc-content">
          <div><strong>{{ $t('business_other_amenity') }}</strong></div>
          <div class="ti28" v-html="hotelDetail.Descriptions.business_amenities">
          </div>
        </div>
        <div class="hotel-policy-content">
          <div><strong>{{ $t('hotel_size') }}</strong></div>
          <span class="hotel-policy-item" v-for="item in hotelDetail.Statistics" v-bind:key="item">
            <CheckOutlined /> <span class="hotel-policy-item-value">{{ item }}</span>
          </span>
        </div>
        <div v-if="hotelDetail.Descriptions.renovations" class="hotel-desc-content error-color">
          <div><strong>{{ $t('renovation_close') }}</strong></div>
          <div v-html="hotelDetail.Descriptions.renovations">
          </div>
        </div>
      </a-row>
      <a-row id="hotel_policy" class="hotel-policy border">
        <div><span class="hotel-desc-title">{{ $t('hotel_policy') }}</span></div>
        <a-divider/>
        <div class="hotel-policy-content">
            <div><strong>{{ $ta('checkin|and|checkout') }}</strong></div>
            <span class="hotel-policy-item"><CheckOutlined /> {{ $t('hanlde_checkin_time') }}: <span class="hotel-policy-item-value">{{ hotelDetail.Policy.begin_time }} - {{ hotelDetail.Policy.end_time }}</span></span>
            <span class="hotel-policy-item"><CheckOutlined /> {{ $t('checkout_time') }}: <span class="hotel-policy-item-value">{{ hotelDetail.Policy.checkout_time }}</span></span>
            <span class="hotel-policy-item" v-if="hotelDetail.Policy.min_age" ><CheckOutlined /> {{ $t('checkin_min_age', {n: hotelDetail.Policy.min_age}) }} </span>
        </div>
        <div class="hotel-policy-content">
          <div><strong>{{ $t('pet') }}</strong></div>
          <span class="hotel-policy-item" v-for="item in hotelDetail.Pets" v-bind:key="item">
            <CheckOutlined /> <span class="hotel-policy-item-value">{{ item }}</span>
          </span>
        </div>
        <div class="hotel-policy-content1" v-if="hotelDetail.Policy.special_instructions">
          <div><strong>{{ $t('special_check_in_instructions') }}</strong></div>
          <div class="ti28" v-html="hotelDetail.Policy.special_instructions"></div>
        </div>
        <div class="hotel-policy-content1" v-if="hotelDetail.Policy.instructions">
          <div><strong>{{ $t('check_in_instructions') }}</strong></div>
          <div class="ex-content" v-html="hotelDetail.Policy.instructions">
          </div>
        </div>
        <div class="hotel-policy-content1" v-if="hotelDetail.Policy.fee_mandatory">
          <div><strong>{{ $t('mandatory_fees') }}</strong></div>
          <div class="ex-content" v-html="hotelDetail.Policy.fee_mandatory">
          </div>
        </div>
        <div class="hotel-policy-content1" v-if="hotelDetail.Policy.fee_optional">
          <div><strong>{{ $t('optional_extras') }}</strong></div>
          <div class="ex-content" v-html="hotelDetail.Policy.fee_optional">
          </div>
        </div>
        <div class="hotel-policy-content1">
          <div><strong>{{ $t('know_before_you_go') }}</strong></div>
          <div class="ex-content" v-html="hotelDetail.Policy.policie_know_before_you_go">
          </div>
        </div>
        <div class="hotel-policy-content2">
          <div><strong>{{ $t('other_policy') }}</strong></div>
          <div class="ex-content">
            <ul>
              <li class="li2" v-for="item in hotelDetail.Generals" v-bind:key="item">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </a-row>
      <a-row id="hotel_feature" class="hotel-policy border" style="margin-bottom: 30px">
        <div><span class="hotel-desc-title">{{ $t('facility_service') }}</span></div>
        <a-divider/>
        <div class="hotel-policy-content2">
          <div class="ex-content">
            <ul>
              <li class="li5" v-for="item in hotelDetail.Amenities" v-bind:key="item">{{ item }}</li>
            </ul>
          </div>
        </div>
      </a-row>
    </template>
    <template v-else>
      <a-row id="hotel_desc" class="hotel-desc border">
        <div><span class="hotel-desc-title">{{ $t('hotel_overview') }}</span></div>
        <a-divider/>
        <div class="hotel-desc-content" v-if="isZhCn" v-html="getDesc(hotel.hotel_desc_cn)">
        </div>
        <div class="hotel-desc-content" v-else v-html="getDesc(hotel.hotel_desc_en)">
        </div>
        <div class="hotel-policy-content">
          <div v-for="item in this.usefulInfoList" v-bind:key="item.id">
            <template v-if="item.id ==2 || item.id ==4">
              <useful-info-item :useful-info-item="item" :is-zh-cn="this.isZhCn"></useful-info-item>
            </template>
          </div>
        </div>
      </a-row>
      <a-row id="hotel_policy" class="hotel-policy border">
        <div><span class="hotel-desc-title">{{ $t('hotel_policy') }}</span></div>
        <a-divider/>
        <div class="hotel-policy-content">
          <div v-for="item in this.usefulInfoList" v-bind:key="item.id">
            <template v-if="item.id !=2 && item.id!=4">
              <useful-info-item :useful-info-item="item" :is-zh-cn="this.isZhCn"></useful-info-item>
            </template>
          </div>
          <div v-if="guestPolicy">
            <div><strong>{{ $t('child_extra_beds') }}</strong></div>
            <div>{{ guestPolicy.extraBedPolicy }}</div>
            <div>{{ guestPolicy.minimumAgeOfGuest }}</div>
            <div v-if="guestPolicy.infantPolicy !== null && !isEmpty(guestPolicy.infantPolicy.ageDescription)">
              <CheckOutlined/>
              {{ guestPolicy.infantPolicy.ageDescription }}: {{ guestPolicy.infantPolicy.policy }}.
              [{{ guestPolicy.infantPolicy.additionalInfo }}]
            </div>
            <div v-if="guestPolicy.childPolicy !== null && !isEmpty(guestPolicy.childPolicy.ageDescription)">
              <CheckOutlined/>
              {{ guestPolicy.childPolicy.ageDescription }}: {{ guestPolicy.childPolicy.policy }}
              [{{ guestPolicy.childPolicy.additionalInfo }}]
            </div>
            <div v-if="guestPolicy.adultChildPolicy !== null && !isEmpty(guestPolicy.adultChildPolicy.ageDescription)">
              <CheckOutlined/>
              {{ guestPolicy.adultChildPolicy.ageDescription }}: {{ guestPolicy.adultChildPolicy.policy }}
              [{{ guestPolicy.adultChildPolicy.additionalInfo }}]
            </div>
          </div>
          <div v-if="importantNotes && importantNotes.length > 0">
            <div><strong>{{ $t('important_note') }}</strong></div>
            <template v-for="str in importantNotes" v-bind:key="str">
              <div>
                <CheckOutlined/>
                <span v-html="getPolicy(str)"></span></div>
            </template>
          </div>
          <div v-if="otherPolicy && otherPolicy.length > 0">
            <div><strong>{{ $t('other_policy') }}</strong></div>
            <template v-for="str in otherPolicy" v-bind:key="str">
              <div>
                <CheckOutlined/>
                <span v-html="getPolicy(str)"></span></div>
            </template>
          </div>
        </div>
      </a-row>
      <a-row id="hotel_feature" class="hotel-policy border" style="margin-bottom: 30px"
             v-if="featureList && featureList.length > 0">
        <div><span class="hotel-desc-title">{{ $t('facility_service') }}</span></div>
        <a-divider/>
        <div class="hotel-policy-content">
          <div v-for="group in featureList.filter(item => item.feature_group_id === 0 && item.feature_id !== 50)"
               v-bind:key="group.id">
          <span class="hotel-policy-content-fea-group"><strong>{{
              isZhCn ? group.name_cn : group.name_en
            }}</strong></span>
            <template v-for="fac in featureList.filter(item => item.feature_group_id === group.feature_id)"
                      v-bind:key="fac.id">
              <span class="hotel-policy-content-fea"><CheckOutlined/> {{ isZhCn ? (isEmpty(fac.name_cn) ? fac.name_en : fac.name_cn) : fac.name_en }}</span>
            </template>
          </div>
        </div>
      </a-row>
    </template>

  </div>
  <div class="body1" v-else>
    <a-spin :tip="$t('hotel_info_loading')" :spinning="true" size="large">
      <div style="width: 100%;height: 500px"></div>
    </a-spin>
  </div>

  <div v-if="floatNav" style="position: fixed;top:0;padding: 0 150px;width: 100%;background-color: #FFFFFF;box-shadow: 0 2px 5px 0 #e1e2e7;">
    <hotel-search ref="hotel_search_detail_top" @searchGo="searchGo" :prop-keywords="propKeywords"></hotel-search>
    <div v-if="floatTitleNav" style="padding:15px 10px;display: flex;align-items: center;justify-content: space-between;width:100%">
      <span>
      <a :class="clickButtonName === 'hotel_room'?'button-hover':'button'"
         @click="scrollToTarget('hotel_room')">{{ $t('room_price') }}</a>
      <a :class="clickButtonName === 'hotel_desc'?'button-hover':'button'"
         @click="scrollToTarget('hotel_desc')">{{ $t('hotel_overview') }}</a>
      <a :class="clickButtonName === 'hotel_policy'?'button-hover':'button'"
         @click="scrollToTarget('hotel_policy')">{{ $t('hotel_policy') }}</a>
      <a :class="clickButtonName === 'hotel_feature'?'button-hover':'button'"
         @click="scrollToTarget('hotel_feature')">{{ $t('facility_service') }}</a>
      </span>
      <a-space v-if="!rateLoading && this.roomRates.length > 0">
        <a-select :bordered="false" :dropdownMatchSelectWidth="false" :options="roomOptions" v-model:value="roomSelected" @change="scrollToTarget('hotel_room')">
        </a-select>
        <a-select :bordered="false" :dropdownMatchSelectWidth="false" :options="mealOptions" v-model:value="mealSelected" >
        </a-select>
        <a-checkbox v-model:checked="refundableChecked" @change="scrollToTarget('hotel_room')">{{ $t('refundable') }}</a-checkbox>
        <a-checkbox v-model:checked="hotelPackage" @change="searchRooms(searchData)" v-if="hasERate">{{ $t('check_out_airfare_combination_specials') }}</a-checkbox>
      </a-space>
    </div>
  </div>

  <a-modal v-model:visible="imageWin" :width="1000" :footer="null" :centered="true">
    <a-row>
      <a-col :span="24">
        <a-carousel ref="images" arrows :dots="false" :autoplay="false" effect="fade" :afterChange="afterSlideChange">
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 5px; z-index: 1">
              <LeftCircleFilled :style="{ color: '#234BA1'}"/>
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 5px">
              <RightCircleFilled :style="{ color: '#234BA1'}"/>
            </div>
          </template>
          <div v-for="image in imageList" v-bind:key="image.id">
            <div style="width: 100%;height:400px;display: flex;justify-content: center;align-items: center;">
              <a-image :src="hotelDetail.PropertyId ? getEImageUrl(image,true):getImageUrl(image, true)" :preview="false" style="padding: 30px;max-height: 500px"/>
            </div>
          </div>
        </a-carousel>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <div style="max-height:240px;overflow: auto;overflow-y:auto;padding-left: 28px">
          <template v-for="(image,index) in imageList" v-bind:key="image.id">
            <div :class="index == slideIndex?'hotel-slider-image hotel-slider-image-selected':'hotel-slider-image'"
                 :style="{ backgroundImage: 'url(\'' + (hotelDetail.PropertyId ? getEImageUrl(image):getImageUrl(image)) + '\')'}"
                 @click="this.$refs.images.goTo(index,false);">
            </div>
          </template>
        </div>
      </a-col>
    </a-row>
  </a-modal>

  <a-modal v-model:visible="mapWin" :width="1000" :footer="null" :centered="true">
    <div id="map-container" :style="mapContainerStyle">
    </div>
  </a-modal>

  <a-modal v-model:visible="bedTypeSelectWin" :title="$t('select_bedtype')" :okText="$ta('submit|reserve')" :centered="true" @ok="submitSelectBedType" :okButtonProps="{ disabled: bedTypeSelected === undefined }">
    <a-radio-group v-model:value="bedTypeSelected">
      <template v-for="(bed,index) in bedTypes" v-bind:key="index">
        <a-radio :value="bed.id" :style="{display: 'flex'}">{{ bed.description }}</a-radio>
      </template>
    </a-radio-group>
    <div style="margin-top: 15px;">
      <span class="error-color">
        <InfoCircleOutlined />
        {{ $t('select_bedtype_warning') }}
      </span>
    </div>
  </a-modal>

  <a-modal v-model:visible="roomDetailWin" :width="600" :footer="null" :centered="true">
    <template #title>
      {{ isZhCn ? getNameCn(selectRoom.room_name_cn, selectRoom.room_name_en) : selectRoom.room_name_en}}
    </template>
    <a-carousel ref="images" arrows :dots="false" :autoplay="false" effect="fade" :afterChange="afterSlideChange">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 5px; z-index: 1">
          <LeftCircleFilled :style="{ color: '#fff'}"/>
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 5px">
          <RightCircleFilled :style="{ color: '#fff'}"/>
        </div>
      </template>
      <div v-for="(item, index) in selectRoom.Details.links_jsons" v-bind:key="index">
        <div style="width: 100%;height:300px;display: flex;justify-content: center;align-items: center;">
          <a-image :src="getLinkJsonImageUrl(item, true)" :preview="false" />
        </div>
      </div>
    </a-carousel>
    <div class="ex-content1" v-html="selectRoom.Details.overview">
    </div>
  </a-modal>

</template>

<script>
import {inject, shallowRef} from "vue";
import dayjs from 'dayjs';
import {
  StarFilled,
  HeartOutlined,
  HeartFilled,
  LeftCircleFilled,
  RightCircleFilled,
  EnvironmentFilled,
  CheckOutlined,
  UpOutlined,
  DownOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  InfoCircleOutlined,
  CarOutlined,
  CheckCircleFilled
} from '@ant-design/icons-vue';
import SvgBed from '@/assets/icon/bed.svg';
import SvgSqm from '@/assets/icon/sqm.svg';
import SvgSmoking from '@/assets/icon/smoking.svg';
import SvgNonSmoking from '@/assets/icon/non-smoking.svg';
import HotelSearch from "@/views/hotel/Search";
import {
  isEmpty,
  getMapMarkerIconUrl,
  getImageUrl,
  getEImageUrl,
  getBedTypeStr,
  getNameCn,
  getNameCnEn,
  isRefundable,
  getCancelDatetime,
  isERoom,getECancelText,isEpsPartiallyRefundable,isChina
} from "@/utils/util";
import {getHotelDetail, searchRoomRates, saveFavorite, cancelFavorite} from "@/services/hotel";
import AMapLoader from "@amap/amap-jsapi-loader";
import UsefulInfoItem from "@/views/hotel/UsefulInfoItem";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  name: "HotelDetail",
  components: {
    UsefulInfoItem,
    HotelSearch,
    StarFilled,
    HeartOutlined,
    HeartFilled,
    LeftCircleFilled,
    RightCircleFilled,
    EnvironmentFilled,
    CheckOutlined,
    UpOutlined,
    DownOutlined,UpCircleOutlined,DownCircleOutlined,TeamOutlined,PlusCircleOutlined,InfoCircleOutlined,CarOutlined,CheckCircleFilled,
    SvgSmoking,SvgNonSmoking,
    SvgBed,SvgSqm
  },
  data() {
    const map = shallowRef(null)

    let mealTypes = this.$tm('mealtypes')

    return {
      dayjs,
      selectedMenuKeys: inject('selectedMenuKeys'),
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      hotelDetail: {},
      hotel: {},
      imageList: [],
      image1: undefined,
      image2: undefined,
      image3: undefined,
      usefulInfoList: [],
      guestPolicy: undefined,
      otherPolicy: undefined,
      importantNotes: undefined,
      featureList: [],
      propKeywords: '',
      initLoading: true,
      rateLoading: false,
      imageWin: false,
      slideIndex: 0,
      mapWin: false,
      map,
      mapContainerStyle: { height: '500px', width: '100%', padding: '0', margin: '0', backgroundColor: '#e2e9fa'},
      clickButtonName: 'hotel_room',
      roomRates: [],
      roomOptions:[],
      mealTypes,
      mealOptions: [],
      roomImages:[],
      rateTrShows: [],
      lowestShows: [],
      refundableChecked: false,
      roomSelected: -1,
      mealSelected: -1,
      searchData: {},
      floatNav: false,
      floatTitleNav: false,
      hotelPackage: false,
      hasERate: false,
      bedTypeSelectWin: false,
      bedTypeSelected: undefined,
      bedTypes:[],
      roomDetailWin: false,
      selectRoom: null,
      selectReserveRoom: null,
      selectReserveRate: null,
    }
  },
  beforeMount() {
    window._AMapSecurityConfig = {
      securityJsCode:'fd923bbc30bb9a6ca9767fa4ccdb79e4',
    }
    this.inntRouterParams()
    this.getDetail()
    this.selectedMenuKeys = ['hotel_index']
  },
  mounted() {
    this.searchRooms(this.searchData)
    window.addEventListener('scroll', this.handleScroll, true)
  },
  unmounted() {
    if(this.map) {
      if(this.map.destroy)this.map.destroy()
      if(this.map.remove)this.map.remove()
    }
    window.removeEventListener('scroll',this.handleScroll)
  },
  watch: {
    '$route.params': function (value1, value2) {
      if (value1.HotelId !== value2.HotelId)
        this.getDetail()
    },
  },
  computed: {
    getRoomRates() {
      let tempRoomRates = JSON.parse(JSON.stringify(this.roomRates))

      if(this.roomSelected >= 0)
        tempRoomRates = tempRoomRates.filter(item => item.room_id === this.roomSelected)

      if(this.refundableChecked){
        for(let i=0;i < tempRoomRates.length;i++) {
          tempRoomRates[i].RatePlans = tempRoomRates[i].RatePlans.filter(item => isRefundable(item.CancelAdvanceHour, dayjs(this.searchData.CheckIn), dayjs()))
        }
      }
      if(this.mealSelected >= 0){
        for(let i=0;i < tempRoomRates.length;i++) {
          tempRoomRates[i].RatePlans = tempRoomRates[i].RatePlans.filter(item => item.MealType == this.mealSelected)
        }
      }

      tempRoomRates.forEach((room,index) => {
        this.rateTrShows[index] = room.RatePlans.length <= 4
      })

      return tempRoomRates
    }
  },
  methods: {
    isEmpty,
    getImageUrl,
    getEImageUrl,
    getBedTypeStr,
    getNameCn,
    getNameCnEn,
    isRefundable,
    getCancelDatetime,
    isERoom,getECancelText,isEpsPartiallyRefundable,
    getLinkJsonImageUrl(links_json, isBigImg = true){
      if(isEmpty(links_json))
        return ''
      let obj = JSON.parse(links_json)
      if(isBigImg === true) {
        return obj['1000px'].href
      } else if(isBigImg === false) {
        return obj['350px'].href
      } else {
        return obj['70px'].href
      }
    },
    inntRouterParams(){
      if(this.$route.params && this.$route.params.HotelId)
        this.searchData.HotelId = parseInt(this.$route.params.HotelId)
      else
        this.$router.push({ name: 'home'})

      if(this.$route.query) {
        if (!isEmpty(this.$route.query.CheckIn))
          this.searchData.CheckIn = this.$route.query.CheckIn
        else
          this.searchData.CheckIn = dayjs().add(1, 'day').format('YYYY-MM-DD')

        if (!isEmpty(this.$route.query.CheckOut))
          this.searchData.CheckOut = this.$route.query.CheckOut
        else
          this.searchData.CheckOut = dayjs().add(2, 'day').format('YYYY-MM-DD')

        if (!isEmpty(this.$route.query.PaxRoom))
          this.searchData.PaxRoom = JSON.parse(this.$route.query.PaxRoom)
        else
          this.searchData.PaxRoom = {
            Count: 1,
            Adults: 2,
            Children: [],
            Nationality: 'CN'
          }
      }
    },
    getProfile(desc) {
      if (desc)
        return '<strong>' + this.$t('hotel_desc') + ':</strong>&nbsp;&nbsp;' + desc.replace(/<b>[^</>]*<\/b>/g, '').replace(/<br>|<br\/>/g, '')
      else
        return ''
    },
    getDesc(desc) {
      if (desc)
        return desc.replace(/<br>[\S]*<br>/g, '<br>').replace(/^<br>|<br>$/g, '')
      else
        return ''
    },
    getPolicy(str) {
      if (str)
        return str.replace(/<br\/><br\/>/g, '<br>').replace(/<br><br>/g, '<br>')
      else
        return ''
    },
    getUnitPrice(totalPrice){
      return totalPrice / dayjs(this.searchData.CheckOut).diff(dayjs(this.searchData.CheckIn), 'days') / this.searchData.PaxRoom.Count
    },
    afterSlideChange(index) {
      this.slideIndex = index
    },
    searchGo(searchData) {
      if (searchData.HotelId > 0) {
        this.$router.push({
          name: 'hotel_detail',
          params: {HotelId: searchData.HotelId},
          query: {
            CheckIn: searchData.CheckIn,
            CheckOut: searchData.CheckOut,
            PaxRoom: JSON.stringify(searchData.PaxRoom),
          }
        })
        if (searchData.HotelId === parseInt(this.$route.params.HotelId)) {
          this.searchRooms(searchData)
          this.scrollToTarget('hotel_room')
        }else{
          this.floatNav = false
          this.floatTitleNav = false
          window.scrollTo({
            top: 0
          });
        }
      } else {
        this.$router.push({
          name: 'hotel_list',
          params: {
            CityCode: searchData.CityCode
          },
          query: {
            CheckIn: searchData.CheckIn,
            CheckOut: searchData.CheckOut,
            PaxRoom: JSON.stringify(searchData.PaxRoom),
            Keyword: searchData.Keyword,
            SubCityCode: searchData.SubCityCode
          }
        })
      }
    },
    getDetail() {
      this.initLoading = true
      getHotelDetail(this.$route.params.HotelId, this.$store.getters["setting/getLanguage"]).then(res => {
        const resData = res.data
        if (resData.Success) {
          let _this = this
          setTimeout(function () {
            _this.initLoading = false
          }, 500)

          this.hotelDetail = resData.Data
          this.hotel = resData.Data.HotelInfo
          if(this.hotelDetail?.Rating?.star > 0)
            this.hotel.hotel_star = this.hotelDetail.Rating.star

          this.imageList = resData.Data.ImageList
          this.imageList.forEach(item => {
            if(item.links_json)
              item.links_json = JSON.parse(item.links_json)
          })
          if (this.imageList.length >= 1)
            this.image1 = this.imageList[0]
          if (this.imageList.length >= 2)
            this.image2 = this.imageList[1]
          if (this.imageList.length >= 3)
            this.image3 = this.imageList[2]
          this.usefulInfoList = resData.Data.UsefulInfoList
          if (resData.Data.HotelDetail) {
            if (this.isZhCn) {
              this.guestPolicy = !isEmpty(resData.Data.HotelDetail.guest_policy_cn) ? JSON.parse(resData.Data.HotelDetail.guest_policy_cn) : undefined
              this.otherPolicy = !isEmpty(resData.Data.HotelDetail.other_policy_cn) ? JSON.parse(resData.Data.HotelDetail.other_policy_cn) : undefined
              this.importantNotes = !isEmpty(resData.Data.HotelDetail.important_note_cn) ? JSON.parse(resData.Data.HotelDetail.important_note_cn) : undefined
            } else {
              this.guestPolicy = !isEmpty(resData.Data.HotelDetail.guest_policy_en) ? JSON.parse(resData.Data.HotelDetail.guest_policy_en) : undefined
              this.otherPolicy = !isEmpty(resData.Data.HotelDetail.other_policy_en) ? JSON.parse(resData.Data.HotelDetail.other_policy_en) : undefined
              this.importantNotes = !isEmpty(resData.Data.HotelDetail.important_note_en) ? JSON.parse(resData.Data.HotelDetail.important_note_en) : undefined
            }
          }
          this.featureList = resData.Data.FeatureList

          this.propKeywords = this.isZhCn ? getNameCn(this.hotel.hotel_name_cn,this.hotel.hotel_name_en) : this.hotel.hotel_name_en
        } else {
          console.log(resData)
          this.$message.error(this.$t('error.' + resData.Code))
          this.initLoading = true
        }
      }).catch(error => {
        console.log(error)
        this.initLoading = true
      })
    },
    getRoomImageUrl(roomId){
      let images = this.imageList.filter(item => item?.room_id === roomId)
      if(images.length >= 1)
        return getImageUrl(images[0])
      else
        return undefined
    },
    searchRooms(searchData) {
      this.searchData = JSON.parse(JSON.stringify(searchData))
      this.searchData.CurrenyCode = this.$store.getters["setting/getCurrency"]
      this.rateLoading = true
      searchData.CurrenyCode = this.searchData.CurrenyCode
      searchData.Language = this.$store.getters["setting/getLanguage"]
      searchData.hotelPackage = this.hotelPackage
      searchRoomRates(searchData).then(res => {
        this.rateLoading = false
        const resData = res.data
        if (resData.Success) {
          this.roomRates = resData.Data ? resData.Data : []
          this.roomOptions = []
          this.roomOptions.push({value:-1, label: this.$t('all_room')})

          let mealArr = []
          this.roomRates.forEach(item =>{
            this.lowestShows[item.room_id] = true
            this.roomImages[item.room_id] = this.getRoomImageUrl(item.room_id)
            if(this.isZhCn)
              this.roomOptions.push({value:item.room_id, label: getNameCn(item.room_name_cn,item.room_name_en)})
            else
              this.roomOptions.push({value:item.room_id, label: item.room_name_en})

            item.RatePlans.forEach(rate => {
              mealArr[rate.MealType] = rate.MealType
              if(isERoom(rate.RatePlanId)){
                if(rate?.ExtraData?.EpsRateInfo){
                  this.hasERate = true
                  rate.EpsRateInfo = JSON.parse(rate.ExtraData.EpsRateInfo)
                  rate.ExtraData.EpsRateInfo = undefined
                }
              }
            })
          })

          this.mealOptions = [{ value: -1, label: this.$t('meal_type') }]
          for(let key in this.mealTypes) {
            let value = parseInt(key)
            if(mealArr.indexOf(value) >= 0)
              this.mealOptions.push({ value: value, label: this.mealTypes[key] })
          }

        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
        this.rateLoading = false
      })
    },
    initMap() {
      this.mapWin = true
      if(isChina(this.hotel.country_code)) {
        AMapLoader.load({
          key: "449ad181436161774915f814e8bc44b6",
          //version: "2.0",
          plugins: [],
        }).then((AMap) => {
          if (!isEmpty(this.hotel.hotel_longitude) && !isEmpty(this.hotel.hotel_latitude) && this.map === null) {
            let _this = this
            let lnglat = [this.hotel.hotel_longitude, this.hotel.hotel_latitude]
            _this.map = new AMap.Map("map-container", {
              lang: _this.isZhCn ? 'zh_en' : 'en',
              vectorMapForeign: _this.isZHCN ? 'style_zh_cn' : 'style_en',
              viewMode: "2D",
              resizeEnable: true,
              zoom: 10,
              center: lnglat
            })

            let marker = new AMap.Marker({
              position: lnglat,
              offset: new AMap.Pixel(-10, -10),
              icon: getMapMarkerIconUrl(), // 添加 Icon 图标 URL
            })
            _this.map.add(marker)
            _this.map.setFitView();

            AMap.plugin(['AMap.ToolBar'], function () {
              _this.map.addControl(new AMap.ToolBar({
                liteStyle: true
              }))
            })
          }
        }).catch(e => {
          console.log(e);
        })
      }else {
        if (!isEmpty(this.hotel.hotel_longitude) && !isEmpty(this.hotel.hotel_latitude)) {
          if (this.map === null) {
            let _this = this
            mapboxgl.accessToken = 'pk.eyJ1IjoiZGFsaWFuamlkdSIsImEiOiJjbHc0aXY2eTkwbGMwMnRyem5oemNjc3duIn0._eDBBmjmpIf3G2rOoy7AOA';
            setTimeout(function () {
              let lnglat = [_this.hotel.hotel_longitude, _this.hotel.hotel_latitude]

              _this.map = new mapboxgl.Map({
                container: 'map-container',
                style: 'mapbox://styles/mapbox/streets-v12',
                center: lnglat,
                zoom: 15,
              })

              const el = document.createElement('div')
              el.style.backgroundImage = `url(${getMapMarkerIconUrl()})`
              el.style.width = `34px`
              el.style.height = `40px`
              el.style.backgroundSize = '100%'
              new mapboxgl.Marker(el).setLngLat(lnglat).addTo(_this.map)

              _this.map.addControl(new mapboxgl.NavigationControl({
                showCompass: false
              }), 'bottom-right')
              _this.map.resize()
            }, 300)
          }
        }
      }
    },
    scrollToTarget(id) {
      let element = document.getElementById(id);
      this.clickButtonName = id
      let headerOffset = 175;
      if(id === 'hotel_room')
        headerOffset = 110
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
    handleScroll(){
      let element = document.getElementById('hotel_search')
      if(element) {
        if (element.getBoundingClientRect()?.top < 10) {
          this.floatNav = true
        } else {
          this.floatNav = false
        }
      }

      let element1 = document.getElementById('hotel_room')
      if(element1) {
        if (element1.getBoundingClientRect()?.top < 100) {
          this.floatTitleNav = true
        } else {
          this.floatTitleNav = false
        }
      }
    },
    clickFavorite(){
      if(this.hotel?.favorite_id){
        cancelFavorite(this.hotel.hotel_id)
        this.hotel.favorite_id = undefined
      }else {
        saveFavorite(this.hotel.hotel_id)
        this.hotel.favorite_id = 1
      }
    },
    goReserve(room, rate){
      if(isERoom(rate.RatePlanId) && rate.EpsRateInfo && Object.keys(rate.EpsRateInfo.bed_groups).length > 1){
        this.bedTypes = rate.EpsRateInfo.bed_groups
        this.bedTypeSelectWin = true
        this.bedTypeSelected = undefined
        this.selectReserveRoom = room
        this.selectReserveRate = rate
      }else {
        let preOrderKey = this.hotel.hotel_id + '.' + dayjs().valueOf()
        let _room = JSON.parse(JSON.stringify(room))
        _room.SearchData = this.searchData
        _room.SearchData.BedGroupId = this.bedTypeSelected
        _room.SearchData.HotelPackage = this.hotelPackage
        _room.SearchData.Language = this.$store.getters["setting/getLanguage"]
        _room.lower_price = undefined
        _room.RatePlans = undefined
        _room.Rate = JSON.parse(JSON.stringify(rate))

        sessionStorage.setItem(process.env.VUE_APP_PRE_ORDER_KEY_PREFIX + preOrderKey, JSON.stringify(_room))

        let routeUrl = this.$router.resolve({
          name: "booking_fill",
          params: { PreOrderKey:preOrderKey }
        });

        window.open(routeUrl.href, '_blank');
      }
    },
    submitSelectBedType(){
      let _rate = JSON.parse(JSON.stringify(this.selectReserveRate))

      Object.keys(_rate.EpsRateInfo.bed_groups).forEach(key => {
        if(key !== this.bedTypeSelected)
          delete _rate.EpsRateInfo.bed_groups[key]
      })

      this.bedTypes = []
      this.bedTypeSelectWin = false
      this.goReserve(this.selectReserveRoom, _rate)
      this.bedTypeSelected = undefined
      this.selectReserveRoom = null
      this.selectReserveRate = null
    }
  }
}
</script>

<style scoped>
.hotel-detail {
  padding: 10px;
}

.hotel-detail p,.hotel-detail .p {
  margin-bottom: 10px;
}

.hotel-detail .ant-divider-horizontal {
  margin: 10px 0 5px 0;
}

.hotel-detail .hotel-name {
  font-size: 18px;
  font-weight: bolder;
}

.hotel-detail .hotel-profile {
  text-overflow: '-o-ellipsis-lastline';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.hotel-detail .hotel-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
}

.hotel-slider-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  float: left;
}

.hotel-slider-image-selected {
  transform: scale3d(1.2, 1.2, 1);
}

.hotel-slider-image:hover {
  transform: scale3d(1.2, 1.2, 1);
}

.hotel-detail .image-total {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
  padding: 8px;
}

.ant-carousel :deep(.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  opacity: 1;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 1;
}

.hotel-desc, .hotel-policy, .hotel-room {
  padding: 20px 10px;
  margin-top: 20px;
}

.hotel-desc-content, .hotel-policy-content {
  padding-left: 15px;
  padding-right: 30px;
  line-height: 40px;
  width: 100%;
}

.hotel-desc-content, .hotel-policy-content p,
.hotel-desc-content, .hotel-policy-content ul,
.hotel-desc-content, .hotel-policy-content li {
  line-height: 30px;
}

.hotel-desc .ant-divider-horizontal, .hotel-policy .ant-divider-horizontal {
  margin: 15px 0 5px 0;
}

.hotel-policy-content-fea-group {
  margin-right: 20px;
  font-weight: 500;
}

.hotel-policy-content-fea {
  margin-right: 15px;
}

.hotel-policy-content1 {
  padding: 5px 30px 10px 15px;
  line-height: 30px;
  width: 100%;
}

.hotel-policy-content2 {
  padding: 5px 0 10px 15px;
  line-height: 30px;
  width: 100%;
}

.button {
  color: #000000;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin-right: 15px;
}

.button-hover {
  color: #000000;
  font-weight: 600;
  padding: 0 0 10px 0;
  margin-right: 15px;
  border-bottom: 4px solid #234BA1;
}

.button:hover {
  border-bottom: 4px solid #234BA1;
}

.hotel-room .ant-divider-horizontal {
  margin: 20px 0 15px 0;
}

.hotel-room .ant-spin-nested-loading {
  width: 100%;
}

.hotel-room svg{
  fill: #000000;
  /*fill-opacity: 0.6;*/
}
.hotel-room svg.adult {
  width: 24px;
  height: 24px;
  margin: 0 -6px;
}
.hotel-room svg.child {
   width: 16px;
   height: 16px;
   margin: 0 -3px;
 }
.hotel-room svg.room {
  width: 15px;
  height: 15px;
  margin-top: 5px;
}
.hotel-room svg.flash {
  width: 12px;
  height: 12px;
  margin-bottom: -2px;
}
.table-rooms {
  width:100%;
}
.table-rooms .room-name {
  background-color: #E4EDFF;
  color: #234BA1;
  font-weight: 600;
  padding: 10px;
  text-align: left;
}
.table-rooms tr:hover td {
  background-color: rgba(205, 212, 220, 0.3);
}
.table-rooms tr:hover td[rowspan] {
  background-color: #FBFBFB;
}
.table-rooms thead th {
  background: none;
  padding: 15px;
}
.table-rooms td {
  background-color: #FBFBFB;
}
.table-rooms td {
  padding: 10px;
}
.table-rooms td[rowspan] {
  padding: 10px;
}
.table-rooms .rateplanname {
  font-weight: 500;
  max-width: 270px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.occ-svg{
  display: flex;
  justify-content: center;
  align-items: center;
}
.lowest_price {
  float: right;
  cursor: pointer;
}
.ref-font {
  font-size: 12px;color: rgba(0,0,0,0.7)
}


.price_detail {
  width: 300px;
}

.price_detail .ant-divider-horizontal {
  margin: 10px 0;
  font-size: 13px;
  color: #234BA1;
}

.price_detail .ant-divider-horizontal svg {
  width: 18px;
  height: 18px;
  margin-bottom: -5px;
}

.price_detail table {
  width: 100%;
}

.price_detail table td {
  padding: 1px;
}

.hotel-policy-item {
  margin-right: 20px;
}
.hotel-policy-item-value {
  font-weight: 500;
}

.guest_rating {
  color: rgba(35, 75, 161, 0.6);
  background-color: rgba(228, 237, 255, 0.3);
  padding: 1px 8px;
  border-radius: 5px;
  line-height: 22px;
  font-size: 13px;
  cursor: pointer;
}
.guest_rating strong {
  color: #4268ad;
  font-size: 15px;
}

.li2,.li5 {
  float:left;
  word-break: keep-all;
  word-wrap:break-word;
  white-space:pre-wrap;
  padding-right: 10px;
}

.li2 {
  width: 50%;
}

.li5 {
  width: 20%;
}

</style>